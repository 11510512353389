import React from 'react';
import { graphql } from 'gatsby';
import { PageWithSubNav, ItemsListIndexPage } from 'docComponents';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Guidelines"
      designCode
      subnav="guidelines">
      <ItemsListIndexPage data={data} listType="visual" listLayout="3" />
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query guidelinesDesignIndexQuery($tierOne: String = "Guidelines") {
    ...indexList
  }
`;
